import { Link, useNavigate, useMatch } from 'react-router-dom';

function Nav() {
  const navigate = useNavigate();
  const isPrivacyPolicy = useMatch('/privacy-policy');
  const toggleNav = () => {
    const body = document.documentElement;
    const menu = document.querySelector('#menu');
    document.querySelectorAll('.stick').forEach((elem) => {
      if (elem.classList.contains('open')) {
        elem.classList.remove('open')
        elem.classList.add('close')
        body.style.overflow = 'unset';
        menu.style.transform = 'translate(-100%, 0)';
      } else {
        elem.classList.remove('close')
        elem.classList.add('open')
        body.style.overflow = 'hidden';
        menu.style.transform = 'none';
      }
    })
  }

  const scrollToTargetAdjusted = (target) => {
    var element = window.document.querySelector(target);
    var headerOffset = window.document.querySelector('#nav').offsetHeight;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  return (
    <nav id="nav" className=" sticky top-0 left-0 right-0 bg-[#F1F3F5] z-50">
      <div className="container flex items-center justify-between px-5 py-1 mx-auto lg:py-3 max-w-7xl">
        <Link to='/' className='z-50'>
          <svg width="52" height="64" viewBox="0 0 52 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_36_753)">
              <path d="M52 28.7561C52 25.0149 51.209 21.7637 49.6417 19.0173C48.0744 16.256 45.8772 14.133 43.0648 12.6484C41.9662 12.0695 40.809 11.5944 39.5786 11.2529C38.3921 10.9115 37.1617 10.674 35.858 10.5403C35.8287 10.5403 35.7994 10.5403 35.7701 10.5403H23.8175C23.5831 10.5403 23.3487 10.6591 23.2169 10.867L20.5656 14.905L15.8783 22.0458L14.5454 24.0648C14.4721 24.1687 14.311 24.1242 14.311 23.9906V13.9697C14.311 13.7025 14.2085 13.4501 14.018 13.2571L1.23042 0.222556C0.776338 -0.252507 0 0.0740989 0 0.727312V57.4084C0 58.0616 0.776338 58.3882 1.23042 57.928L14.0473 44.8934C14.2377 44.7004 14.3403 44.448 14.3403 44.1808V33.284C14.3403 33.1504 14.5014 33.1059 14.5746 33.2098L15.4828 34.7092L20.7854 43.4534L23.0997 47.2687C23.2316 47.4914 23.4659 47.6102 23.7149 47.6102H35.6383C35.6676 47.6102 35.6969 47.6102 35.7262 47.6102C37.1324 47.4617 38.4654 47.1648 39.7544 46.7343C40.4135 46.5116 41.058 46.2592 41.7025 45.9623C44.222 44.7746 46.302 43.1713 47.9572 41.1226C49.5831 39.1184 50.6524 36.847 51.1944 34.3232C51.209 34.249 51.1504 34.1599 51.0625 34.1599H36.4732C36.2096 34.1599 35.9752 34.3084 35.8434 34.5311C35.1403 35.7039 34.0856 36.2977 32.6794 36.2977C32.3718 36.2977 32.0642 36.268 31.7859 36.2086C31.0682 36.075 30.4676 35.7633 29.9696 35.2882C29.3837 34.7092 28.9882 33.7146 28.8124 32.2894L28.7977 32.1854C28.7538 31.8588 29.0028 31.5768 29.3251 31.5768H51.5606C51.6192 31.5768 51.6777 31.5322 51.6924 31.4728C51.8975 30.5376 52 29.632 52 28.7561ZM29.8377 25.7275C29.3397 25.7275 28.9589 25.2376 29.1054 24.7477C29.5448 23.2038 30.453 22.2536 31.8445 21.8825C32.3132 21.734 32.8406 21.6747 33.4118 21.6747C34.569 21.6747 35.5358 22.0013 36.3121 22.6693C36.9127 23.1889 37.2789 23.9164 37.4107 24.8516C37.4839 25.3119 37.1177 25.7424 36.649 25.7424H29.8377V25.7275Z" fill="#212529" />
              <path d="M0.893535 62.5895L2.21184 63.9999H1.87494L0.659169 62.6786V63.9999H0.366211V61.1792H0.644521V62.5005L1.8603 61.1792H2.1972L0.893535 62.5895Z" fill="#212529" />
              <path d="M4.57024 63.3167H3.29588L3.04686 63.9996H2.75391L3.77926 61.2383H4.10151L5.12686 63.9996H4.83391L4.57024 63.3167ZM4.49701 63.094L3.95503 61.5797L3.38377 63.094H4.49701Z" fill="#212529" />
              <path d="M7.54387 61.1792V61.4019H6.73824V63.9999H6.45993V61.4019H5.66895V61.1792H7.54387Z" fill="#212529" />
              <path d="M10.3705 61.1792V63.9999H10.0922V62.6638H8.58349V63.9999H8.30518V61.1792H8.58349V62.4262H10.0922V61.1792H10.3705Z" fill="#212529" />
              <path d="M12.978 63.3167H11.7036L11.4546 63.9996H11.1616L12.187 61.2383H12.4946L13.5199 63.9996H13.227L12.978 63.3167ZM12.8901 63.094L12.3481 61.5797L11.7915 63.094H12.8901Z" fill="#212529" />
              <path d="M15.9223 63.6287C15.8637 63.7474 15.7758 63.8365 15.644 63.9107C15.5268 63.985 15.3657 64.0146 15.1753 64.0146C14.9995 64.0146 14.8384 63.985 14.6919 63.9107C14.5454 63.8513 14.4429 63.7474 14.355 63.6435C14.2671 63.5247 14.2231 63.3911 14.2085 63.2575H14.5015C14.5161 63.3466 14.5454 63.4208 14.5893 63.5099C14.6333 63.599 14.7065 63.6583 14.8091 63.7177C14.8969 63.7771 15.0141 63.8068 15.146 63.8068C15.3217 63.8068 15.4536 63.7623 15.5561 63.6583C15.644 63.5693 15.7026 63.4357 15.7026 63.2872C15.7026 63.1684 15.6733 63.0645 15.6147 62.9754C15.5561 62.9012 15.4829 62.8418 15.3803 62.7973C15.2924 62.7528 15.1606 62.7082 15.0141 62.6637C14.8384 62.6043 14.7065 62.5598 14.5893 62.5004C14.4868 62.441 14.3989 62.3668 14.3257 62.2628C14.2524 62.1589 14.2085 62.0253 14.2085 61.862C14.2085 61.6542 14.2817 61.476 14.4282 61.3424C14.5747 61.2088 14.7798 61.1494 15.0434 61.1494C15.3071 61.1494 15.5122 61.2088 15.6733 61.3424C15.8344 61.476 15.9223 61.6393 15.9516 61.8323H15.6586C15.6293 61.7136 15.5707 61.6096 15.4682 61.5057C15.3657 61.4166 15.2338 61.3721 15.0581 61.3721C14.8969 61.3721 14.7651 61.4166 14.6626 61.5057C14.56 61.5948 14.5161 61.7136 14.5161 61.862C14.5161 61.9808 14.5454 62.0847 14.604 62.1589C14.6626 62.2332 14.7358 62.2925 14.8237 62.3371C14.9116 62.3816 15.0288 62.4261 15.1899 62.4707C15.3657 62.5301 15.5122 62.5746 15.6147 62.634C15.7172 62.6934 15.8198 62.7676 15.893 62.8715C15.9662 62.9754 16.0102 63.1239 16.0102 63.302C16.0102 63.3911 15.9809 63.5099 15.9223 63.6287Z" fill="#212529" />
              <path d="M18.603 61.4168V62.4857H19.687V62.7084H18.603V63.7921H19.8041V64.0297H18.3247V61.209H19.8041V61.4317H18.603V61.4168Z" fill="#212529" />
              <path d="M22.7773 63.9999H22.499L20.9756 61.6394V63.9999H20.6973V61.1792H20.9756L22.499 63.5397V61.1792H22.7773V63.9999Z" fill="#212529" />
              <path d="M25.3993 61.1792V61.4019H24.6084V63.9999H24.33V61.4019H23.5391V61.1792H25.3993Z" fill="#212529" />
              <path d="M26.4541 61.4168V62.4857H27.538V62.7084H26.4541V63.7921H27.6552V64.0297H26.1758V61.209H27.6552V61.4317H26.4541V61.4168Z" fill="#212529" />
              <path d="M30.0283 63.9999L29.2666 62.8271H28.8125V63.9999H28.5342V61.1792H29.3838C29.6914 61.1792 29.9257 61.2534 30.0869 61.4019C30.248 61.5503 30.3212 61.7582 30.3212 62.0106C30.3212 62.2481 30.2626 62.4262 30.1308 62.5747C29.999 62.7083 29.8085 62.7974 29.5595 62.8271L30.3505 64.0147H30.0283V63.9999ZM28.8125 62.6192H29.3691C29.8232 62.6192 30.0429 62.4262 30.0429 62.0254C30.0429 61.6246 29.8232 61.4316 29.3691 61.4316H28.8125V62.6192Z" fill="#212529" />
              <path d="M32.7968 61.1792V61.4019H32.0058V63.9999H31.7275V61.4019H30.9365V61.1792H32.7968Z" fill="#212529" />
              <path d="M35.1694 63.3167H33.895L33.646 63.9996H33.353L34.3784 61.2383H34.686L35.7113 63.9996H35.4184L35.1694 63.3167ZM35.0961 63.094L34.5542 61.5797L33.9829 63.094H35.0961Z" fill="#212529" />
              <path d="M36.7808 61.1792V63.9999H36.5024V61.1792H36.7808Z" fill="#212529" />
              <path d="M39.8715 63.9999H39.5932L38.0698 61.6394V63.9999H37.7915V61.1792H38.0698L39.5932 63.5397V61.1792H39.8715V63.9999Z" fill="#212529" />
              <path d="M43.5629 61.2383V63.9996H43.2846V61.6837L42.3471 63.9996H42.0981L41.146 61.6985V63.9996H40.8677V61.2383H41.1899L42.2153 63.6581L43.2406 61.2383H43.5629Z" fill="#212529" />
              <path d="M44.8371 61.4168V62.4857H45.9211V62.7084H44.8371V63.7921H46.0383V64.0297H44.5588V61.209H46.0383V61.4317H44.8371V61.4168Z" fill="#212529" />
              <path d="M49.0119 63.9999H48.7336L47.2102 61.6394V63.9999H46.9319V61.1792H47.2102L48.7336 63.5397V61.1792H49.0119V63.9999Z" fill="#212529" />
              <path d="M51.6337 61.1792V61.4019H50.8427V63.9999H50.5644V61.4019H49.7734V61.1792H51.6337Z" fill="#212529" />
            </g>
            <defs>
              <clipPath id="clip0_36_753">
                <rect width="52" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        {isPrivacyPolicy ?
          <div className="xl:gap-16 lg:flex gap-4 md:gap-8 items-center text-[#212529] font-bold xl:text-2xl lg:text-[34px] text-lg [&>div:hover]:text-[#E03131] [&>div]:cursor-pointer [&>div]:transition-all [&>div]:duration-300">
            <div
              onClick={() => {
                navigate('/')
                scrollToTargetAdjusted('#home');
              }}
            >
              Go Home
            </div>
          </div>
          :
          <>
            <div class="nav-container lg:hidden z-50" onClick={toggleNav}>
              <div class="stick stick-1"></div>
              <div class="stick stick-2"></div>
              <div class="stick stick-3"></div>
            </div>
            <div className="lg:gap-16 menu lg:flex gap-4 md:gap-8 hidden items-center text-[#212529] font-bold lg:text-2xl text-lg [&>div:hover]:text-[#E03131] [&>div]:cursor-pointer [&>div]:transition-all [&>div]:duration-300">
              <div
                onClick={() => {
                  scrollToTargetAdjusted('#games');
                }}
                className='games'
              >
                Games
              </div>
              <div
                onClick={() => {
                  scrollToTargetAdjusted('#about');
                }}
                className='about'
              >
                About
              </div>
              <div
                onClick={() => {
                  scrollToTargetAdjusted('#contact');
                }}
                className='contact'
              >
                Contact Us
              </div>
            </div>
          </>}
      </div>

      <div id="menu" className="menu lg:hidden flex flex-col justify-center pl-5 text-5xl gap-8 [&>div]:cursor-pointer text-[#212529] font-bold [&>div:hover]:text-[#E03131] [&>div]:transition-all">
        <div onClick={() => {
          toggleNav();
          scrollToTargetAdjusted('#games');
        }}
          className='games'
        >
          Games
        </div>
        <div onClick={() => {
          toggleNav();
          scrollToTargetAdjusted('#about');
        }}
          className='about'
        >
          About
        </div>
        <div onClick={() => {
          toggleNav();
          scrollToTargetAdjusted('#contact');
        }}
          className='contact'
        >
          Contact Us
        </div>
      </div>
    </nav>
  );
}

export default Nav;