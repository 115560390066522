import { Link } from 'react-router-dom'
function Footer() {
  return (
    <footer className="bg-[#E03131] w-full mt-24 pt-6 pb-4 lg:py-8 overflow-x-hidden">
      <div className="container flex flex-row justify-between gap-6 p-5 px-5 mx-auto md:items-center max-w-7xl">
        <div className="flex flex-col gap-10 xl:gap-20 lg:gap-14 md:items-center md:flex-row">
          <svg width="52"  height="64" viewBox="0 0 52 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_36_1521)">
              <path d="M52 28.7561C52 25.0149 51.209 21.7637 49.6417 19.0173C48.0744 16.256 45.8772 14.133 43.0648 12.6484C41.9662 12.0695 40.809 11.5944 39.5786 11.2529C38.3921 10.9115 37.1617 10.674 35.858 10.5403C35.8287 10.5403 35.7994 10.5403 35.7701 10.5403H23.8175C23.5831 10.5403 23.3487 10.6591 23.2169 10.867L20.5656 14.905L15.8783 22.0458L14.5454 24.0648C14.4721 24.1687 14.311 24.1242 14.311 23.9906V13.9697C14.311 13.7025 14.2085 13.4501 14.018 13.2571L1.23042 0.222556C0.776338 -0.252507 0 0.0740989 0 0.727312V57.4084C0 58.0616 0.776338 58.3882 1.23042 57.928L14.0473 44.8934C14.2377 44.7004 14.3403 44.448 14.3403 44.1808V33.284C14.3403 33.1504 14.5014 33.1059 14.5746 33.2098L15.4828 34.7092L20.7854 43.4534L23.0997 47.2687C23.2316 47.4914 23.4659 47.6102 23.7149 47.6102H35.6383C35.6676 47.6102 35.6969 47.6102 35.7262 47.6102C37.1324 47.4617 38.4654 47.1648 39.7544 46.7343C40.4135 46.5116 41.058 46.2592 41.7025 45.9623C44.222 44.7746 46.302 43.1713 47.9572 41.1226C49.5831 39.1184 50.6524 36.847 51.1944 34.3232C51.209 34.249 51.1504 34.1599 51.0625 34.1599H36.4732C36.2096 34.1599 35.9752 34.3084 35.8434 34.5311C35.1403 35.7039 34.0856 36.2977 32.6794 36.2977C32.3718 36.2977 32.0642 36.268 31.7859 36.2086C31.0682 36.075 30.4676 35.7633 29.9696 35.2882C29.3837 34.7092 28.9882 33.7146 28.8124 32.2894L28.7977 32.1854C28.7538 31.8588 29.0028 31.5768 29.3251 31.5768H51.5606C51.6192 31.5768 51.6777 31.5322 51.6924 31.4728C51.8975 30.5376 52 29.632 52 28.7561ZM29.8377 25.7275C29.3397 25.7275 28.9589 25.2376 29.1054 24.7477C29.5448 23.2038 30.453 22.2536 31.8445 21.8825C32.3132 21.734 32.8406 21.6747 33.4118 21.6747C34.569 21.6747 35.5358 22.0013 36.3121 22.6693C36.9127 23.1889 37.2789 23.9164 37.4107 24.8516C37.4839 25.3119 37.1177 25.7424 36.649 25.7424H29.8377V25.7275Z" fill="#F8F9FA" />
              <path d="M0.893535 62.5895L2.21184 63.9999H1.87494L0.659169 62.6786V63.9999H0.366211V61.1792H0.644521V62.5005L1.8603 61.1792H2.1972L0.893535 62.5895Z" fill="#F8F9FA" />
              <path d="M4.57012 63.3167H3.29576L3.04674 63.9996H2.75378L3.77914 61.2383H4.10139L5.12674 63.9996H4.83378L4.57012 63.3167ZM4.49688 63.094L3.95491 61.5797L3.38364 63.094H4.49688Z" fill="#F8F9FA" />
              <path d="M7.54363 61.1792V61.4019H6.738V63.9999H6.45969V61.4019H5.6687V61.1792H7.54363Z" fill="#F8F9FA" />
              <path d="M10.3708 61.1792V63.9999H10.0925V62.6638H8.58373V63.9999H8.30542V61.1792H8.58373V62.4262H10.0925V61.1792H10.3708Z" fill="#F8F9FA" />
              <path d="M12.9781 63.3167H11.7037L11.4547 63.9996H11.1617L12.1871 61.2383H12.4947L13.5201 63.9996H13.2271L12.9781 63.3167ZM12.8902 63.094L12.3482 61.5797L11.7916 63.094H12.8902Z" fill="#F8F9FA" />
              <path d="M15.9223 63.6287C15.8637 63.7474 15.7758 63.8365 15.644 63.9107C15.5268 63.985 15.3657 64.0146 15.1753 64.0146C14.9995 64.0146 14.8384 63.985 14.6919 63.9107C14.5454 63.8513 14.4429 63.7474 14.355 63.6435C14.2671 63.5247 14.2231 63.3911 14.2085 63.2575H14.5015C14.5161 63.3466 14.5454 63.4208 14.5893 63.5099C14.6333 63.599 14.7065 63.6583 14.8091 63.7177C14.8969 63.7771 15.0141 63.8068 15.146 63.8068C15.3217 63.8068 15.4536 63.7623 15.5561 63.6583C15.644 63.5693 15.7026 63.4357 15.7026 63.2872C15.7026 63.1684 15.6733 63.0645 15.6147 62.9754C15.5561 62.9012 15.4829 62.8418 15.3803 62.7973C15.2924 62.7528 15.1606 62.7082 15.0141 62.6637C14.8384 62.6043 14.7065 62.5598 14.5893 62.5004C14.4868 62.441 14.3989 62.3668 14.3257 62.2628C14.2524 62.1589 14.2085 62.0253 14.2085 61.862C14.2085 61.6542 14.2817 61.476 14.4282 61.3424C14.5747 61.2088 14.7798 61.1494 15.0434 61.1494C15.3071 61.1494 15.5122 61.2088 15.6733 61.3424C15.8344 61.476 15.9223 61.6393 15.9516 61.8323H15.6586C15.6293 61.7136 15.5707 61.6096 15.4682 61.5057C15.3657 61.4166 15.2338 61.3721 15.0581 61.3721C14.8969 61.3721 14.7651 61.4166 14.6626 61.5057C14.56 61.5948 14.5161 61.7136 14.5161 61.862C14.5161 61.9808 14.5454 62.0847 14.604 62.1589C14.6626 62.2332 14.7358 62.2925 14.8237 62.3371C14.9116 62.3816 15.0288 62.4261 15.1899 62.4707C15.3657 62.5301 15.5122 62.5746 15.6147 62.634C15.7172 62.6934 15.8198 62.7676 15.893 62.8715C15.9662 62.9754 16.0102 63.1239 16.0102 63.302C16.0102 63.3911 15.9809 63.5099 15.9223 63.6287Z" fill="#F8F9FA" />
              <path d="M18.6028 61.4168V62.4857H19.6867V62.7084H18.6028V63.7921H19.8039V64.0297H18.3245V61.209H19.8039V61.4317H18.6028V61.4168Z" fill="#F8F9FA" />
              <path d="M22.7775 63.9999H22.4992L20.9758 61.6394V63.9999H20.6975V61.1792H20.9758L22.4992 63.5397V61.1792H22.7775V63.9999Z" fill="#F8F9FA" />
              <path d="M25.3995 61.1792V61.4019H24.6085V63.9999H24.3302V61.4019H23.5392V61.1792H25.3995Z" fill="#F8F9FA" />
              <path d="M26.4541 61.4168V62.4857H27.538V62.7084H26.4541V63.7921H27.6552V64.0297H26.1758V61.209H27.6552V61.4317H26.4541V61.4168Z" fill="#F8F9FA" />
              <path d="M30.0281 63.9999L29.2665 62.8271H28.8124V63.9999H28.5341V61.1792H29.3836C29.6912 61.1792 29.9256 61.2534 30.0867 61.4019C30.2479 61.5503 30.3211 61.7582 30.3211 62.0106C30.3211 62.2481 30.2625 62.4262 30.1307 62.5747C29.9988 62.7083 29.8084 62.7974 29.5594 62.8271L30.3504 64.0147H30.0281V63.9999ZM28.8124 62.6192H29.369C29.8231 62.6192 30.0428 62.4262 30.0428 62.0254C30.0428 61.6246 29.8231 61.4316 29.369 61.4316H28.8124V62.6192Z" fill="#F8F9FA" />
              <path d="M32.7967 61.1792V61.4019H32.0057V63.9999H31.7274V61.4019H30.9364V61.1792H32.7967Z" fill="#F8F9FA" />
              <path d="M35.1696 63.3167H33.8952L33.6462 63.9996H33.3533L34.3786 61.2383H34.6862L35.7116 63.9996H35.4186L35.1696 63.3167ZM35.0964 63.094L34.5544 61.5797L33.9831 63.094H35.0964Z" fill="#F8F9FA" />
              <path d="M36.7809 61.1792V63.9999H36.5026V61.1792H36.7809Z" fill="#F8F9FA" />
              <path d="M39.8715 63.9999H39.5932L38.0698 61.6394V63.9999H37.7915V61.1792H38.0698L39.5932 63.5397V61.1792H39.8715V63.9999Z" fill="#F8F9FA" />
              <path d="M43.5629 61.2383V63.9996H43.2846V61.6837L42.3471 63.9996H42.0981L41.146 61.6985V63.9996H40.8677V61.2383H41.1899L42.2153 63.6581L43.2406 61.2383H43.5629Z" fill="#F8F9FA" />
              <path d="M44.8371 61.4168V62.4857H45.9211V62.7084H44.8371V63.7921H46.0383V64.0297H44.5588V61.209H46.0383V61.4317H44.8371V61.4168Z" fill="#F8F9FA" />
              <path d="M49.0119 63.9999H48.7336L47.2102 61.6394V63.9999H46.9319V61.1792H47.2102L48.7336 63.5397V61.1792H49.0119V63.9999Z" fill="#F8F9FA" />
              <path d="M51.6338 61.1792V61.4019H50.8429V63.9999H50.5645V61.4019H49.7736V61.1792H51.6338Z" fill="#F8F9FA" />
            </g>
            <defs>
              <clipPath id="clip0_36_1521">
                <rect width="52" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flex gap-3 md:gap-9">
            <a href="#" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5L2 27C2 27.7956 2.31607 28.5587 2.87868 29.1213C3.44129 29.6839 4.20435 30 5 30H13.5781V20.4806H9.64062V16H13.5781V12.585C13.5781 8.70062 15.8906 6.555 19.4325 6.555C21.1287 6.555 22.9025 6.8575 22.9025 6.8575V10.67H20.9481C19.0225 10.67 18.4219 11.865 18.4219 13.0906V16H22.7206L22.0331 20.4806H18.4219V30H27C27.7956 30 28.5587 29.6839 29.1213 29.1213C29.6839 28.5587 30 27.7956 30 27V5C30 4.20435 29.6839 3.44129 29.1213 2.87868C28.5587 2.31607 27.7956 2 27 2Z" fill="#F8F9FA" />
              </svg>
            </a>
            <a href="#" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 2H3.99375C2.89375 2 2 2.90625 2 4.01875V27.9813C2 29.0938 2.89375 30 3.99375 30H28C29.1 30 30 29.0938 30 27.9813V4.01875C30 2.90625 29.1 2 28 2ZM10.4625 26H6.3125V12.6375H10.4688V26H10.4625ZM8.3875 10.8125C7.05625 10.8125 5.98125 9.73125 5.98125 8.40625C5.98125 7.08125 7.05625 6 8.3875 6C9.7125 6 10.7937 7.08125 10.7937 8.40625C10.7937 9.7375 9.71875 10.8125 8.3875 10.8125ZM26.0187 26H21.8687V19.5C21.8687 17.95 21.8375 15.9563 19.7125 15.9563C17.55 15.9563 17.2188 17.6438 17.2188 19.3875V26H13.0688V12.6375H17.05V14.4625H17.1062C17.6625 13.4125 19.0188 12.3062 21.0375 12.3062C25.2375 12.3062 26.0187 15.075 26.0187 18.675V26Z" fill="#F8F9FA" />
              </svg>
            </a>
            <a href="#" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27 2H5C3.34375 2 2 3.34375 2 5V27C2 28.6562 3.34375 30 5 30H27C28.6562 30 30 28.6562 30 27V5C30 3.34375 28.6562 2 27 2ZM23.9438 11.925C23.9563 12.1 23.9562 12.2812 23.9562 12.4563C23.9562 17.875 19.8312 24.1187 12.2937 24.1187C9.96875 24.1187 7.8125 23.4438 6 22.2812C6.33125 22.3188 6.65 22.3312 6.9875 22.3312C8.90625 22.3312 10.6687 21.6812 12.075 20.5812C10.275 20.5437 8.7625 19.3625 8.24375 17.7375C8.875 17.8312 9.44375 17.8313 10.0938 17.6625C8.21875 17.2812 6.8125 15.6312 6.8125 13.6375V13.5875C7.35625 13.8937 7.99375 14.0813 8.6625 14.1062C8.10027 13.7323 7.63936 13.2249 7.32092 12.6295C7.00247 12.034 6.83639 11.369 6.8375 10.6938C6.8375 9.93125 7.0375 9.23125 7.39375 8.625C9.4125 11.1125 12.4438 12.7375 15.8438 12.9125C15.2625 10.1313 17.3438 7.875 19.8438 7.875C21.025 7.875 22.0875 8.36875 22.8375 9.16875C23.7625 8.99375 24.65 8.65 25.4375 8.18125C25.1313 9.13125 24.4875 9.93125 23.6375 10.4375C24.4625 10.35 25.2625 10.1188 26 9.8C25.4438 10.6188 24.7437 11.3438 23.9438 11.925Z" fill="#F8F9FA" />
              </svg>
            </a>
          </div>
        </div>
        <div className='flex flex-col justify-end gap-6 text-sm lg:gap-8 lg:flex-row md:text-lg'>
          <a href="mailto:mail@kathasentertainment.com" className='font-medium text-white underline'>Email</a>
          <Link to='/privacy-policy' className="font-medium text-white underline">Privacy Policy</Link>
          <span className="font-medium text-white"><span className="mr-1 font-base">©</span>Copyright 2022</span>
        </div>

      </div>
    </footer>
  );
}

export default Footer;